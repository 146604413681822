export function stickyHeader($) {
  /**
   * Sticky Header
   * https://osvaldas.info/examples/auto-hide-sticky-header/?reactive-plus
   */
  const elSelector = ".header",
    elClassNarrow = "header--narrow",
    elNarrowOffset = 100,
    $element = $(elSelector);

  if (!$element.length) return true;

  const $document = $(document),
    $window = $(window);

  let elHeight = 0,
    elTop = 0,
    dHeight = 0,
    wHeight = 0,
    wScrollCurrent = 0,
    wScrollBefore = 0,
    wScrollDiff = 0;

  $window.on("scroll", () => {
    elHeight = $element.outerHeight();
    dHeight = $document.height();
    wHeight = $window.height();
    wScrollCurrent = $window.scrollTop();
    wScrollDiff = wScrollBefore - wScrollCurrent;
    elTop = parseInt($element.css("top")) + wScrollDiff;

    $element.toggleClass(elClassNarrow, wScrollCurrent > elNarrowOffset); // toggles "narrow" classname

    // remove transition
    $element.css("transition", "");

    if (wScrollCurrent <= 0)
      // scrolled to the very top; element sticks to the top
      $element.css("top", 0);
    else if (wScrollDiff > 0)
      // scrolled up; element slides in
      $element.css({
        "top": 0,
        "transition": "top 0.2s ease"
      });
    else if (wScrollDiff < 0) {
      // scrolled down
      if (wScrollCurrent + wHeight >= dHeight - elHeight)
        // scrolled to the very bottom; element slides in
        $element.css(
          "top",
          (elTop = wScrollCurrent + wHeight - dHeight) < 0 ? elTop : 0
        );
      // scrolled down; element slides out
      else $element.css("top", Math.abs(elTop) > elHeight ? -elHeight : elTop);
    }

    wScrollBefore = wScrollCurrent;
  });
}
