import Typed from "typed.js";

export function heroBanner($) {
  if ($(".hero-banner-typed").length < 1) return;

  const typedSettings = {
    strings: [
      "digital^3000",
      "brand^3000",
      "user^3000",
      "social^3000",
      "human^3000",
      "service^3000",
    ],
    typeSpeed: 50,
    backSpeed: 25,
    startDelay: 0,
    showCursor: false,
    loop: true,
    loopCount: Infinity,
  };

  const heroBannerTyped = new Typed(".hero-banner-typed", typedSettings);
}
