export function formsExtra($) {
  $("input, textarea").each(function (index, el) {
    $(el).on("input", function (event) {
      event.preventDefault();
      const element = $(this);

      if (element.val()) {
        element.addClass("has-value");
      } else {
        element.removeClass("has-value");
      }
    });
  });
}
