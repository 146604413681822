const { DateTime } = require("luxon");

export const officeClock = ($) => {
  const displayTime = () => {
    const $clocks = $('.js-office-clock');
    const now = DateTime.now();

    // We're only expecting 1 clock per page so we get
    // the time within the .each().
    $clocks.each((index, el) => {
      const $clock = $(el);
      const timeString = now.setZone($clock.data('timezone')).toFormat('h:mm a');

      const currentText = $clock.text();

      // update if there's a change
      if (currentText !== timeString) {
        $clock.text(timeString);
      }
    });

  };

  // update the clock every second
  setInterval(displayTime, 1000);

  // initial display
  displayTime();
};
