export const unmuteAutoplayVideo = ($) => {
  const playButtons = $(".js-play-button");

  if (playButtons.length < 1) return;

  playButtons.each((index, el) => {
    const button = $(el);
    const videosWrapper = button.closest(".js-playable-videos-wrapper");

    if (videosWrapper.length < 1) return;

    button.click(function () {
      const videoSilent = videosWrapper.find(".js-video-silent");
      const videoWithControls = videosWrapper.find(".js-video-with-controls");

      if (videoSilent.length < 1 || videoWithControls.length < 1) return;

      const iframeSilent = videoSilent.find("iframe");
      const iframeWithControls = videoWithControls.find("iframe");

      if (iframeSilent.length < 1 || iframeWithControls.length < 1) return;

      const playerSilent = new Vimeo.Player(iframeSilent[0]);
      const playerWithControls = new Vimeo.Player(iframeWithControls[0]);

      // Hide and stop the background video.
      playerSilent.setVolume(0);
      playerSilent.pause();
      videoSilent.removeClass("visible").addClass("hidden");

      // Hide play button.
      button.hide();
      button.closest(".js-play-button-wrapper").addClass("hidden");

      // Show and play the video with controls
      playerWithControls.setVolume(1);
      videoWithControls.removeClass("hidden").addClass("visible");
      playerWithControls.setCurrentTime(0);
      playerWithControls.play();
    });
  });
};
