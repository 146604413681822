import { mapStyles as mapStylesLight } from "../maps/styleGreyLight.js";
import { mapStyles as mapStylesDark } from "../maps/styleGreyDark.js";
import { scriptSrc } from "../maps/scriptSrc.js";
import { logger } from "../utilities/logger.js";

export const officeMap = ($) => {
  // check if maps exist
  const $maps = $('.js-office-map');

  if ($maps.length < 1) {
    logger.log('office maps: no maps');
    return;
  }

  const $body = $('body');
  const $window = $(window);

  const minWindowWidth = 992;

  // Create the script tag, set the appropriate attributes
  const script = document.createElement("script");
  script.src = `${scriptSrc}&callback=initOfficeMaps`;
  script.defer = true;
  script.async = true;

  const markers = {
    'blue': "marker-blue.png",
    'magenta': "marker-magenta.png",
    'lightMatcha': "marker-light-matcha.png",
    'mint': "marker-mint.png",
    'lightGrey': "marker-light-grey.png",
  };

  const markersSmall = {
    'blue': "marker-sm-blue.png",
    'magenta': "marker-sm-magenta.png",
    'lightMatcha': "marker-sm-light-matcha.png",
    'mint': "marker-sm-mint.png",
    'lightGrey': "marker-sm-light-grey.png",
  };

  const markerDirectory = 'https://www.daveclarkdesign.com/theme/images/map/';

  const getMapIcon = (colour) => {
    let markerIcon = {
      filename: markers[colour] ?? "marker-blue.png",
      width: 87,
      height: 84,
    };

    if ($window.width() < minWindowWidth) {
      markerIcon = {
        filename: markersSmall[colour] ?? "marker-sm-blue.png",
        width: 32,
        height: 31,
      };
    }

    return {
      url: `${markerDirectory}${markerIcon.filename}`,
      size: new google.maps.Size(markerIcon.width, markerIcon.height),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(markerIcon.width / 2, markerIcon.height / 2),
    };
  };


  // attach your callback function to the `window` object
  window.initOfficeMaps = () => {
    // check if light or dark bg and assign correct styles
    const mapStyles = $body.hasClass('dark-background') ? mapStylesDark : mapStylesLight;

    $maps.each((index, el) => {
      const $mapElement = $(el);

      const colour = $mapElement.data('map-colour');
      const mapLat = $mapElement.data('map-latitude');
      const mapLong = $mapElement.data('map-longitude');

      // check if data exists
      if (!colour || !mapLat || !mapLong) {
        logger.log('office maps: missing data');
        return;
      }

      const position = new google.maps.LatLng(mapLat, mapLong);

      const map = new google.maps.Map(
        $mapElement[0],
        {
          center: position,
          zoom: 16,
          styles: mapStyles,
          disableDefaultUI: true,
        }
      );

      const markerIcon = getMapIcon(colour);

      const marker = new google.maps.Marker({
        position: position,
        icon: markerIcon,
        map: map,
      });

      if (typeof window.ResizeObserver !== "function") {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        const newIcon = getMapIcon(colour);

        if (newIcon.url !== marker.getIcon().url) {
          logger.log(`update map icon to ${newIcon}`);
          marker.setIcon(newIcon);
        }
      });

      resizeObserver.observe(document.querySelector("body"));

    });
  };

  // Append the 'script' element to 'head'
  document.head.appendChild(script);
};
