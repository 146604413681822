import { logger } from "../utilities/logger.js";

export function peopleStack($) {

  const $stack = $('.js-people-stack');
  const $stackDisplay = $stack.find('.js-people-stack-display');

  if ($stack.length == 0 || $stackDisplay.length == 0) return;

  const minWindowWidth = 992;
  const $window = $(window);

  const DISPLAY_NOT_IN_VIEW = 'hidden';
  const DISPLAY_TOP_VISIBLE = 'top';
  const DISPLAY_ALL_VISIBLE = 'all';
  const DISPLAY_BOTTOM_VISIBLE = 'bottom';

  const attackTheClones = () => {
    $('.js-people-stack-tile--clone').remove();
  };

  const hideAllTiles = () => {
    $('.js-people-stack-tile').removeClass('show');
  }

  const resetTiles = () => {
    attackTheClones();
    hideAllTiles();
  }

  const toggleTile = (event) => {
    // Check the tile exists
    const $tile = $(event.currentTarget).find('.js-people-stack-tile');

    if ($tile.length == 0) return;

    // For smaller screens, just show the tile
    if ($window.width() < minWindowWidth) {
      $tile.toggleClass('show');
      return;
    };

    // Hide all tiles and clones
    resetTiles();

    // Get the width height of the tile
    const tileWidth = $tile.width();
    const tileHeight = $tile.height();

    // Determine what's visible of the display
    const displayWidth = $stackDisplay.width();
    const visibleDisplayHeight = getVisibleDisplayHeight();
    const displayOffsetY = visibleDisplayHeight.offset;

    // Determine the available offset range in the visible part of the display
    const range = getTileOffsetRange(
      displayWidth,
      visibleDisplayHeight.height,
      tileWidth,
      tileHeight,
    );

    // Random x on the display
    const offsetX = range.x ? getRandom(range.x) : 0;

    // Random y if it's positive
    let offsetY = 0;
    if (range.y > 0) {
      offsetY = range.y ? getRandom(range.y) : 0;
    } else if (range.y < 0) {
      offsetY = range.y;
    }

    // For debugging
    logger.table({
      displayWidth: displayWidth,
      displayHeight: $stackDisplay.height(),
      tileWidth: tileWidth,
      tileHeight: tileHeight,
      rangeX: range.x,
      rangeY: range.y,
      randomOffsetTileX: offsetX,
      randomOffsetTileY: offsetY,
      displayOffsetY: displayOffsetY,
      totalOffsetFromDisplay0Height: displayOffsetY + offsetY,
    });

    // Clone, add styles and place
    $tile.clone()
      .css({
        'position': 'absolute',
        'left': offsetX,
        'display': 'block',
        'opacity': 1,
        'top': displayOffsetY + offsetY,
        'z-index': 100
      })
      .addClass('js-people-stack-tile--clone clone')
      .appendTo($stackDisplay);
  }

  $stack.find('.js-people-stack-item').each((index, el) => {
    $(el).on("touchend click mouseenter", (event) => {
      event.stopPropagation();
      event.preventDefault();

      toggleTile(event);
    });
  });

  function getRandom(max) {
    return Math.floor(Math.random() * (max + 1));
  }

  const getTileOffsetRange = (visibleDisplayWidth, visibleDisplayHeight, tileWidth, tileHeight) => {
    const {
      windowHeight,
      windowTop,
      windowBottom,
      displayHeight,
      displayTop,
      displayBottom,
    } = getDisplayAndWindowSpecs();

    const displayVisibilityState = getDisplayVisibilityState(
      windowTop,
      windowBottom,
      displayTop,
      displayBottom
    );

    // if tile is taller than display area or bottom of area is visible, tile stays on top
    // if not, get the vertical range
    let limitY = 0;
    switch (displayVisibilityState) {
      case DISPLAY_ALL_VISIBLE:
      case DISPLAY_BOTTOM_VISIBLE:
        limitY = visibleDisplayHeight - tileHeight;
        break;

      // If the tile is smaller than display, add a range.
      case DISPLAY_TOP_VISIBLE:
        if (tileHeight < visibleDisplayHeight) {
          limitY = visibleDisplayHeight - tileHeight;
        }
        break;
    }

    // if tile is wider than display area, tile stays on left
    // if not, get the horizontal range
    let limitX = 0;
    if (tileWidth < visibleDisplayWidth) {
      limitX = visibleDisplayWidth - tileWidth;
    }

    return {
      x: limitX,
      y: limitY,
    }
  };

  const getDisplayAndWindowSpecs = () => {
    const windowHeight = $window.height();
    const windowTop = $window.scrollTop();
    const windowBottom = windowHeight + windowTop;
    const displayHeight = $stackDisplay.height();
    const displayTop = $stackDisplay.offset().top;
    const displayBottom = displayHeight + displayTop;

    return {
      'windowHeight': windowHeight,
      'windowTop': windowTop,
      'windowBottom': windowBottom,
      'displayHeight': displayHeight,
      'displayTop': displayTop,
      'displayBottom': displayBottom,
    }
  }

  const getDisplayVisibilityState = (
    windowTop,
    windowBottom,
    displayTop,
    displayBottom
  ) => {
    // display has not scrolled into view OR
    // scrolled past the display
    if ((displayTop >= windowBottom &&
      displayBottom >= windowBottom) ||
      (displayTop <= windowTop &&
        displayBottom <= windowTop)) {
      return DISPLAY_NOT_IN_VIEW;
    }

    // whole display is visible
    if (displayTop == windowTop &&
      displayBottom == windowBottom) {
      return DISPLAY_ALL_VISIBLE;
    }

    // top part of display has scrolled in
    if (displayTop >= windowTop &&
      displayTop <= windowBottom &&
      displayBottom >= windowTop &&
      displayBottom >= windowBottom) {
      return DISPLAY_TOP_VISIBLE;
    }

    // bottom part of display is visible, started scrolling out
    if (displayTop <= windowTop &&
      displayBottom >= windowTop &&
      displayBottom <= windowBottom) {
      return DISPLAY_BOTTOM_VISIBLE;
    }
  }

  const getVisibleDisplayHeight = () => {
    const {
      windowHeight,
      windowTop,
      windowBottom,
      displayHeight,
      displayTop,
      displayBottom,
    } = getDisplayAndWindowSpecs();

    let visibleScreenTop = 0;
    let visibleScreenBottom = 0;

    const displayVisibilityState = getDisplayVisibilityState(
      windowTop,
      windowBottom,
      displayTop,
      displayBottom
    );

    switch (displayVisibilityState) {
      case DISPLAY_TOP_VISIBLE:
        visibleScreenTop = displayTop;
        visibleScreenBottom = windowBottom;
        break;

      case DISPLAY_ALL_VISIBLE:
        visibleScreenTop = displayTop;
        visibleScreenBottom = displayBottom;
        break;

      case DISPLAY_BOTTOM_VISIBLE:
        visibleScreenTop = windowTop;
        visibleScreenBottom = displayBottom;
        break;

      default:
      case DISPLAY_NOT_IN_VIEW:
        visibleScreenTop = 0;
        visibleScreenBottom = 0;

    }

    const top = visibleScreenTop > 0 ? visibleScreenTop - displayTop : 0;
    const bottom = visibleScreenBottom > 0 ? visibleScreenBottom - displayTop : 0;

    // For debugging
    logger.table({
      'windowHeight': windowHeight,
      'windowTop': windowTop,
      'windowBottom': windowBottom,
      'displayHeight': displayHeight,
      'displayTop': displayTop,
      'displayBottom': displayBottom,
      'visibleScreenTop': visibleScreenTop,
      'visibleScreenBottom': visibleScreenBottom,
      'top': top,
      'bottom': bottom,
    });

    return {
      offset: top,
      height: bottom - top,
    };
  };

  $window.on('scroll', () => {
    logger.log(getVisibleDisplayHeight());
  });

  $window.on('resize', () => {
    // Hide all tiles and clones
    resetTiles();
  });
}
