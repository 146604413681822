export function hubspotContactForm($) {
  if ($("#contact-embed-form").length < 1) return;

  // https://stackoverflow.com/a/21903119
  const getUrlParameter = function(sParam) {
    const sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&');
    let sParameterName;
    let i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };

  const contactForm = function(siteName) {
    const contactEmbedDivId = '#contact-embed-form';
    // Check if the embed DOM element is there.
    if ($(contactEmbedDivId).length == 0) { return; }

    const originalSiteName = siteName;
    let formId;

    // Check for an override variable to the siteName.
    const formOverride = getUrlParameter('contactForm');
    if (typeof formOverride !== 'undefined' && ['NZ', 'AU', 'SG'].indexOf(formOverride.toUpperCase()) !== -1) {
      siteName = formOverride.toUpperCase();
    }

    // Select the specific form.
    switch (siteName) {
      case 'AU':
        formId = '9479b321-1da6-4aba-9a76-e0ccd0874ab5';
        break;

      case 'SG':
        formId = 'd08bcea4-023e-46f4-b3dc-eae852278ac3';
        break;

      case 'NZ':
      default:
        formId = '2c42b0e3-d59c-4656-9b1f-6d021bcc78af';
        break;
    }

    hbspt.forms.create({
      target: contactEmbedDivId,
      portalId: '3401678',
      formId: formId,
      onFormReady: function($form) {
        $('.hs-fieldtype-text, .hs-fieldtype-textarea').each(function(index, el) {
          const fieldHolder = $(el);

          fieldHolder.find('input, textarea').on('focus', function(event) {
            event.preventDefault();
            /* Act on the event */
            fieldHolder.addClass('focused');
          }).on('blur', function(event) {
            event.preventDefault();
            /* Act on the event */
            fieldHolder.removeClass('focused');
          }).on('input change', function(event) {
            event.preventDefault();
            /* Act on the event */
            if ($(this).val() === '') {
              fieldHolder.removeClass('has-value');
            }
            else {
              fieldHolder.addClass('has-value');
            }
          })
        });
      }
    });

    // Add a small note above the form if the form displayed is different
    // from the site the user is on.
    if (originalSiteName !== siteName) {
      let originalSiteFullName = '';
      let siteFullName = '';

      const shortCodes = ['NZ', 'AU', 'SG'];
      const fullCountryNames = ['New Zealand', 'Australia', 'Singapore'];

      shortCodes.forEach(function(item, index) {
        if (originalSiteName === item) { originalSiteFullName = fullCountryNames[index] }
        if (siteName === item) { siteFullName = fullCountryNames[index] }
      });

      // We have only 1 singapore office.
      const office = siteName === 'SG' ? 'office' : 'offices';
      const originalOffice = originalSiteName === 'SG' ? 'office' : 'offices';

      $(contactEmbedDivId).before('<div class="contact-embed-form-note mb-1">This form will send your enquiry to the ' + siteFullName + ' ' + office + '. <a href="/contact/?contactForm=' + originalSiteName + '#contact-form">Click here to send to the ' + originalSiteFullName + ' ' + originalOffice + '</a>.</div>');
    }
  };

  // Check for siteName. and default to NZ if not found.
  // The variable is declared before this file is included
  // like in templates/_includes/singlepage-blocks/contact-form.twig.
  if (typeof siteName === 'undefined') {
    siteName = 'NZ';
  }
  contactForm(siteName);
}
