export const shaveTileText = ($) => {
  const $window = $(window);
  const options = { character: "..." };

  const shaveElements = (selector, maxHeight) => {
    $(selector).each((index, el) => {
      const shavable = $(el);
      const shavableContent = shavable.find(".shave");

      if (shavableContent.length < 1) return;

      const originalTextElement = shavable.find(".original-text");
      let originalText = "";

      if (originalTextElement.length > 1) {
        // Restore original text.
        let originalText = originalTextElement.html();
        shavableContent.html(originalText);
      } else {
        let originalText = shavableContent.html();
        shavable.append(
          '<div class="original-text" style="display: none;">' +
            originalText +
            "</div>"
        );
      }

      shave(shavableContent[0], maxHeight, options);
    });
  };

  const shaveTiles = function () {
    let titleHeight = 68;
    let descHeight = 50;
    if ($window.width() >= 768) {
      titleHeight = 130;
      descHeight = 88;
    }

    shaveElements(".js-service-info-tiles--title", titleHeight);
    shaveElements(".js-service-info-tiles--desc", descHeight);
  };

  let cardShaveTimeout;
  $window.on("resize", function (event) {
    clearTimeout(cardShaveTimeout);
    // Add a timeout so the update doesn't happen every millisecond.
    cardShaveTimeout = setTimeout(function () {
      shaveTiles();
    }, 500);
  });

  setTimeout(function () {
    shaveTiles();
  }, 1000);
};
