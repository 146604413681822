export const showreel = ($) => {
  const bindVimeoVideos = () => {
    const videos = $(".js-showreel-video[data-provider-name='Vimeo']");
    if (videos.length < 1) return;

    videos.each((index, el) => {
      const video = $(el);
      const accordionId = video.data("accordionId");

      if (!accordionId) return;

      const toggle = $(".js-showreel-toggle[data-toggle-target='" + accordionId + "']");

      if (toggle.length < 1) return;

      const state = {
        initialised: false,
        iframe: null,
        player: null,
      }

      toggle.click(() => {
        if (!state.initialised) {
          state.iframe = video.find("iframe");

          if (state.iframe.length < 1) return;

          state.player = new Vimeo.Player(state.iframe[0]);
          state.initialised = true;
        }

        state.player.getPaused().then(function (paused) {
          if (toggle.attr('aria-expanded') == 'true') {
            state.player.play();
            return;
          }
          state.player.pause();
        });
      });
    });
  };

  bindVimeoVideos();


  const bindYouTubeVideos = () => {
    const videos = $(".js-showreel-video[data-provider-name='YouTube']");

    if (videos.length < 1) return;

    // create the script for the API and append it to the DOM
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onPlayerReady = (event) => {
      const iframe = $(event.target.g);
      const player = event.target;
      const accordionId = iframe.data("accordionId");

      if (!accordionId) return;

      const toggle = $(".js-showreel-toggle[data-toggle-target='" + accordionId + "']");

      toggle.click(() => {
        if (toggle.attr('aria-expanded') == 'false') {
          player.playVideo();
          return
        }
        player.pauseVideo();
      });
    }

    window.onYouTubeIframeAPIReady = () => {
      videos.each((index, el) => {
        const video = $(el);

        const loader = video.find('.js-showreel-video-loader').first();

        if (!loader) return;

        const videoId = loader.data('videoId');
        let player;

        if (!videoId) return;
        player = new YT.Player(loader[0], {
          playerVars: {
            controls: 0,
            showinfo: 0,
            autohide: 1
          },
          videoId: videoId,
          events: {
            'onReady': onPlayerReady
          }
        });
      });
    }
  };

  bindYouTubeVideos();
};
