export function backgroundColorTransition($) {
  if ($(".js-background-fade").length < 1) {
    $("body").css("background-color", "#ffffff").addClass("no-hero-banner");
    return;
  }

  const $body = $("body");
  const topTransitionPixels = 200;

  $body.addClass("background-change").css("background-color", "#111111");

  $(window).scroll(function () {
    if ($(window).scrollTop() >= topTransitionPixels) {
      $body.css("background-color", "#ffffff");
      $body.css("color", "#111111");
      //Otherwise remove inline styles and thereby revert to original stying
    } else {
      $body.css("background-color", "#111111");
      $body.css("color", "white");
    }
  });
}
