export function revealAnimations($) {
  const $html = $('html, body');
  const $window = $(window);
  const splittingMinWindowWidth = 768;

  /**
   * Play the animation once when scrolled in.
   */
  const animate = (el, animationClass) => {
    el.classList.add("animate__animated", animationClass);
  };

  /**
   * Play the animation each time scrolled in.
   */
  const reanimate = (el, animationClass) => {
    el.classList.remove("animate__animated", animationClass);

    // force reflow
    void el.offsetWidth;

    // re-add the animated cl
    el.classList.add("animate__animated", animationClass);
  };

  if ($html.hasClass('scroll-reveals')) {
    scrollOut({
      targets: '.scroll-fadeIn',
      onShown: function (el) {
        animate(el, 'animate__fadeIn')
      }
    });

    scrollOut({
      targets: '.scroll-fadeInLeft',
      onShown: function (el) {
        animate(el, 'animate__fadeInLeft')
      }
    });

    scrollOut({
      targets: '.scroll-slideInUp',
      onShown: function (el) {
        animate(el, 'animate__slideInUp')
      }
    });

		// Less intensive animation for mobile.
    if ($window.width() >= splittingMinWindowWidth) {
			splitting({
				targets: '[data-splitting]',
			});
			scrollOut({
				once: true,
				targets: '[data-splitting]',
			});
    } else {
			scrollOut({
				targets: '[data-splitting]',
				onShown: function (el) {
					animate(el, 'animate__fadeIn')
				}
			});
		};
  }
}
