import { logger } from "../utilities/logger.js";

export const lazyLoadVideos = ($) => {
  // check if videos exist
  const $videoWrappers = $('.js-video-wrapper[data-video-code]');

  if ($videoWrappers.length < 1) {
    logger.log('lazy load video banners: no videos');
    return;
  }

  const videoStateMap = new Map();

  // Detect intersection when video is 100% beyond the
  // top or the bottom of the viewport.
  const config = {rootMargin: '100% 0px 100% 0px'};

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(({ target, isIntersecting }) => {
      if (isIntersecting && !videoStateMap.get(target)) {
        const $videoWrapper = $(target);

        const videoCodeRaw = $videoWrapper.data('video-code') ?? '';

        if (videoCodeRaw == '') {
          logger.log('lazy load video banners: no video code');
          return;
        }
        const videoCode = JSON.parse(videoCodeRaw);

        $videoWrapper.append(videoCode);

        videoStateMap.set(target, true);
        logger.log('lazy load video banners: video loading');
      }
    });
  }, config);

  $videoWrappers.each((index, elem) => {
    videoStateMap.set(elem, false);
    observer.observe(elem);
  });
};
