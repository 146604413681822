export function smoothAnchorScrollsOnPage($) {
  const $html = $("html, body");
  $("a[href^='#'], a[href^='/#']").on("click touch", function (event) {
    event.preventDefault();

    // Exclude collapse elements
    const toggle = $(this).data().toggle ?? null;
    if (toggle) {
      return;
    }

    let href = $.attr(this, "href");

    // Remove leading slash if referencing anchor in the current page
    // Since that breaks wrapping in jquery.
    if (href.substring(0, 2) == "/#") {
      href = href.substring(1);
    }

    if (href == "#") {
      return;
    }

    const target = $(href);

    if (target.length <= 0) {
      return;
    }

    $html.animate(
      {
        scrollTop: $(href).offset().top - 50,
      },
      500
    );
  });
}
