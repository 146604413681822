import { rgba2hex } from "../utilities/rgba2hex.js";
import { hex2hsl } from "../utilities/hex2hsl.js";

export const colourShiftTransitions = ($) => {
  const $body = $("body");
  const $window = $(window);
  const $colourWrapper = $body.find(".js-colour-wrapper");
  const $colourShiftEnabled = $body.hasClass("js-enable-colour-shift");
  const $customCssContainer = $body.find(".colour-change-style");
  let previousScroll = $window.scrollTop();

  if (!$colourShiftEnabled || $colourWrapper.length < 1) return;

  const $colourShiftBlocks = $(".js-colour-shift");

  const defaultBackgroundColor = rgba2hex($colourWrapper.css("background-color"));
  const defaultTextColor = rgba2hex($colourWrapper.css("color"));

  const addBodyBackgroundClass = (backgroundColour) => {
    // default to light background if the field is not set or is transparent
    let backgroundClass = "light-background";
    if (backgroundColour != '#00000000') {
      const { lightness } = hex2hsl(backgroundColour);
      backgroundClass = lightness > 50 ? "light-background" : "dark-background";
    }

    if (!$body.hasClass(backgroundClass)) {
      $body.removeClass("light-background dark-background").addClass(backgroundClass);
    }
  };

  const updateCustomCssContainer = (textColour, backgroundColour) => {
    if ($customCssContainer.length < 1) {
      return;
    }

    const headerBackgroundColour = backgroundColour != '#00000000' ? backgroundColour : '#fff';
    const contactFormFieldsBorder = textColour != '#ffffff' ? textColour : 'rgba(255, 255, 255, 0.3)';

    $customCssContainer.each((index, elem) => {
      $(elem).html(
        `<style>
          body {
            background-color: ${backgroundColour} !important;
          }

          .dark-background .colour-change--text,
          .light-background .colour-change--text,
          .colour-change--text,
          .colour-change--text a {
            color: ${textColour};
          }

          .colour-shift.button--cta-white,
          .colour-shift.button--cta-white:visited,
          .colour-shift.button--cta-white:active,
          .colour-shift.button--cta-white:active:hover,
          .block--v3--link-grid--link,
          .block--v3--link-grid--description,
          .read-more a,
          a.small-button,
          .block--accordion--item a,
          .block--accordion--item p,
          .block--accordion--item a:hover,
          .prefooter-link a,
          .block--v3--work-landing-featured-work--tile--title,
          .block--v3--link-cards--title,
          .block--v3--link-stack--title,
          .block--v3--accordion--item a,
          .block--v3--accordion--item p,
          .block--v3--contact-form--title,
          .block--v3--contact-form--hero,
          .block--v3--contact-form label,
          .block--v3--hero-banner {
            color: ${textColour} !important;
          }

          .block--v3--accordion--item--toggle-icon:before,
          .block--v3--accordion--item--toggle-icon:after,
          .block--accordion--item--toggle-icon::before,
          .block--accordion--item--toggle-icon::after {
            background-color: ${textColour} !important;
          }

          .block--v3--contact-form input,
          .block--v3--contact-form textarea {
            color: ${textColour} !important;
            border-color: ${contactFormFieldsBorder} !important;
          }

          .block--v3--contact-form input::placeholder,
          .block--v3--contact-form textarea::placeholder {
            color: ${textColour} !important;
          }

          .colour-shift.button--cta-white,
          .colour-shift.button--cta-white:visited {
            border-color: ${textColour} !important;
          }

          .colour-shift.button--cta-white:hover {
            border-color: ${textColour} !important;
            background-color: ${textColour} !important;
            color: ${backgroundColour} !important;
          }

          .colour-shift.button--cta-white:active,
          .colour-shift.button--cta-white:active:hover {
            border-color: ${textColour} !important;
            filter: brightness(85%);
          }

          .block--accordion--item a:hover {
            filter: brightness(85%);
          }

          .block--accordion--item--toggle {
            border-top-color: ${textColour} !important;
          }

          .block--v3--end-cta {
            border-top-color:${textColour} !important;
            border-bottom-color:${textColour} !important;
          }

          .block--v3--link-stack--list-item svg path {
            stroke: ${textColour} !important;
          }

          .block--v3--link-grid--item {
            border-top-color: ${textColour} !important;
          }

          a.small-button {
            border-color: ${textColour} !important;
            background-color: transparent !important;
          }

          a.small-button:hover {
            color: ${backgroundColour} !important;
            border-color: ${textColour} !important;
            background-color: ${textColour} !important;
          }

          a.small-button--solid {
            color: ${backgroundColour != '#00000000' ? backgroundColour : '#fff'} !important;
            border-color: ${textColour} !important;
            background-color: ${textColour} !important;
          }

          a.small-button--solid:hover {
            color: ${textColour != '#00000000' ? textColour : '#000'} !important;
            border-color: ${textColour} !important;
            background-color: ${backgroundColour} !important;
          }

          .arrow-pseudo::before {
            border-top-color: ${textColour} !important;
            border-right-color: ${textColour} !important;
          }

          .arrow-pseudo::after {
            border-top-color: ${textColour} !important;
          }

          .header--narrow {
            background-color: ${headerBackgroundColour} !important;
          }

          .header .nav-desktop .nav-link,
          .header--narrow .nav-desktop .nav-link {
            color: ${textColour} !important;
          }

          .header .nav-desktop .nav-link:before {
            background-color: ${textColour} !important;
          }

          .header #hamburger:not(.clicked) span,
          .header--narrow #hamburger:not(.clicked) span {
            background-color: ${textColour} !important;
          }

          .dark-background .header--narrow .header--logo--black,
          .dark-background .header .header--logo--black {
            display: none !important;
          }

          .dark-background .header--narrow .header--logo--white,
          .dark-background .header .header--logo--white {
            display: inline !important;
          }


          .light-background .header--narrow .header--logo--white,
          .light-background .header .header--logo--white {
            display: none !important;
          }

          .light-background .header--narrow .header--logo--black,
          .light-background .header .header--logo--black {
            display: inline !important;
          }

          .header .nav-desktop .nav-link.show-as-button,
          .header--narrow .nav-desktop .nav-link.show-as-button {
            color: ${headerBackgroundColour} !important;
            background-color: ${textColour} !important;
            border-color: ${textColour} !important;
          }

        </style>
        `
      );
    });
  };

  const updateColours = () => {
    const currentScroll = $window.scrollTop();
    const windowHeight = $window.height();
    const pageMid = currentScroll + windowHeight / 2;
    let colourBackground = defaultBackgroundColor;
    let colourText = defaultTextColor;

    previousScroll = currentScroll;

    $colourShiftBlocks.each((index, elem) => {
      const $colourBlock = $(elem);
      const colourBlockTop = $colourBlock.offset().top;

      if (colourBlockTop < pageMid) {
        colourBackground = $colourBlock.data("bgcolour");
        colourText = $colourBlock.data("textcolour");
      }
    });

    const cssRules = {};

    if (colourBackground) {
      cssRules["background-color"] = colourBackground;
    }

    if (colourText) {
      cssRules["color"] = colourText;
    }

    $colourWrapper.css(cssRules);
    addBodyBackgroundClass(colourBackground);
    updateCustomCssContainer(colourText, colourBackground);
  };

  // Update on first load.
  updateColours();

  $window.scroll(() => {
    updateColours();
  });
};
