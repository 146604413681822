const dev = process.env.ENABLE_JSDEBUG;
export const logger = {
  log: (message) => {
    if (dev) {
      console.log(message);
    }
  },
  table: (data) => {
    if (dev) {
      console.table(data);
    }
  }
};
