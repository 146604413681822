export function infoPanels($) {
  const infoPanelToggles = $(".info-panel--toggle");

  if (infoPanelToggles.length < 1) return;

  infoPanelToggles.each(function (index, el) {
    $(el).on("click", function (event) {
      event.preventDefault();

      const toggle = $(this);

      toggle.toggleClass("active");

      const panel = toggle.parent().find(".info-panel--panel");

      if (toggle.hasClass("active")) {
        panel.css("max-height", panel[0].scrollHeight + "px");
      } else {
        panel.css("max-height", "");
      }
    });
  });
}
